'use client';
import { type ActivityType } from '@streetferret/api';
import { type Expression } from 'mapbox-gl';
import { useMemo } from 'react';
import {
  Layer,
  type FillLayer,
  type LineLayer,
  type SymbolLayer,
} from 'react-map-gl';
import { useMapTheme } from '../../context';
import { USER_TILES_SOURCE_ID } from './user-tiles-source';

interface BoundaryLayersProps {
  activityType: ActivityType;
  hoveredCityId: number | null;
  selectedCities:
    | {
        cityId: number;
        name: string;
      }[]
    | null;
  /**
   * if provided, only show bounds for these cities
   */
  cityIncludeFilter?: number[];
  /**
   * if provided, exclude these cities
   */
  cityExcludeFilter?: number[];
}
export function BoundaryLayers({
  activityType,
  cityIncludeFilter,
  cityExcludeFilter,
  selectedCities,
  hoveredCityId,
}: BoundaryLayersProps) {
  const { theme, baseTheme } = useMapTheme();
  const isDark = baseTheme === 'dark';
  const isRunType = useMemo(() => activityType === 'run', [activityType]);

  const filterShowCities = useMemo(() => {
    return [
      'all',
      cityIncludeFilter?.length
        ? ['in', ['get', 'cityID'], ['literal', cityIncludeFilter]]
        : true,
      ['!', ['in', ['get', 'cityID'], ['literal', cityExcludeFilter ?? []]]],
    ];
  }, [cityIncludeFilter, cityExcludeFilter]);

  const boundsLayer: LineLayer = useMemo(() => {
    return {
      id: 'city-bounds-outline',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'boundary',
      type: 'line',
      paint: {
        'line-color': theme.bounds,
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          9,
          isDark ? 0.3 : 1, // At zoom level 9, line width is 1
          11,
          1, // At zoom level 11, line width is 2
        ],
      },
      minzoom: 7,
      filter: filterShowCities,
    };
  }, [isDark, theme, filterShowCities]);
  const boundaryLabel: SymbolLayer = useMemo(() => {
    return {
      id: 'boundary-label',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'boundary',
      type: 'symbol',
      layout: {
        'text-font': ['Boundary-Label'],
        'text-max-angle': 15,
        'text-field': cityNameExpr(selectedCities),
        'symbol-placement': 'line',
        'text-anchor': 'center',
        'text-transform': 'uppercase',
        'text-keep-upright': true,
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          10, // Smaller text at lower zoom levels
          14,
          14, // Larger text at higher zoom levels
        ],
        'text-offset': [0, 0.8], // Offset text inside the line
      },
      paint: {
        'text-color': isDark ? '#FFFFFF' : '#111111',
      },
      filter: selectedCitiesFilter(selectedCities),
    };
  }, [isDark, selectedCities]);

  const boundsFillLayer: FillLayer = useMemo(() => {
    return {
      id: 'city-bounds-fill',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'boundary',
      type: 'fill',
      maxzoom: 9,
      paint: {
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', activityType == 'run' ? 'runComplete' : 'cycComplete'],
          0,
          theme.bounds_progress_min,
          0.5,
          theme.bounds_progress_mid,
          1,
          theme.bounds_progress_max,
        ],
      },
      filter: selectedCities
        ? ['all', filterShowCities, selectedCitiesFilter(selectedCities)]
        : filterShowCities,
    };
  }, [theme, filterShowCities, isRunType]);
  const boundsClickLayer: FillLayer = useMemo(() => {
    return {
      id: 'city-bounds-fill-click',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'boundary',
      type: 'fill',
      maxzoom: 13,
      paint: {
        'fill-color': 'rgba(255, 255, 255, 0.0)',
      },
      filter: filterShowCities,
    };
  }, [filterShowCities]);
  const boundsHover: FillLayer = useMemo(() => {
    return {
      id: 'city-bounds-hover',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'boundary',
      type: 'fill',
      maxzoom: 13,
      paint: {
        'fill-color': theme.hover,
      },
      filter: [
        'all',
        ['==', ['get', 'cityID'], hoveredCityId ?? -1],
        // Don't highlight selected cities
        ['!', selectedCitiesFilter(selectedCities)],
        filterShowCities,
      ],
    };
  }, [theme, hoveredCityId, selectedCities, filterShowCities]);
  const selectedBoundsLayer: LineLayer = useMemo(() => {
    return {
      id: 'selected-bounds-layer',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'boundary',
      type: 'line',
      paint: {
        'line-color': theme.hover,
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          9,
          3, // At zoom level 9,
          11,
          4, // At zoom level 11,
        ],
      },
      filter: selectedCitiesFilter(selectedCities),
    };
  }, [selectedCities, theme]);

  return (
    <>
      <Layer beforeId="_background_underlay" {...boundsClickLayer} />
      <Layer beforeId="_background_underlay" {...boundsFillLayer} />
      <Layer beforeId="_background_underlay" {...boundsHover} />
      <Layer beforeId="_background_underlay" {...selectedBoundsLayer} />
      <Layer beforeId="_background_underlay" {...boundsLayer} />
      <Layer beforeId="_road_overlays" {...boundaryLabel} />
    </>
  );
}

function selectedCitiesFilter(
  selectedCities: { cityId: number; name: string }[] | null,
): Expression {
  return [
    'in',
    ['get', 'cityID'],
    ['literal', selectedCities?.map(city => city.cityId) ?? []],
  ];
}

function cityNameExpr(
  selectedCities?: { cityId: number; name: string }[] | null,
): Expression {
  if (!selectedCities || selectedCities.length === 0) {
    return ['literal', '']; // Return default if no cities are provided
  }

  const caseBlock: Expression = ['case'];

  selectedCities.forEach(city => {
    caseBlock.push(['==', ['get', 'cityID'], city.cityId], city.name);
  });

  caseBlock.push(''); // Fallback string if no match
  return caseBlock;
}
